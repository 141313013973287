import React, { useCallback } from 'react';
import { useRequest } from 'ahooks';
import Panel from '@/components/Panel';
import { Area } from '@ant-design/charts';
import { GetIncomeTrendDay,GetIncomeTrendDayV2 } from '@/services/api';
import { Spin } from 'antd'
import ImgSource from '@/assets/img/jingying/shoururiqishi@2x.png'
import { chartDataFill } from '@/utils/chart'
export interface ParkingDailyIncomeProps {
  areaCode?: string;
}

const ParkingDailyIncome:React.FC<ParkingDailyIncomeProps> = ({areaCode}) => {
  const { data = [], loading, error } = useRequest(useCallback(() => {
    // return GetIncomeTrendDay(areaCode,'441800');
    return GetIncomeTrendDayV2(areaCode,'441800');
  },[areaCode]), {
    formatResult: res => {
      return res.data.map((item :any) => {
          let parts = item.dayStr.split("-");
          item.time = parts[1] + "-" + parts[2];
          return item
      })
    },
    refreshDeps: [areaCode]
  })
  
  // const fillData = chartDataFill(data, 'days', 'incomeTotal') // 数据填充
  const fillData = chartDataFill(data, 'dayStr', 'incomeTotal') // 数据填充

  if(loading) {
    return (
      <Panel title="收入日趋势">
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel title="收入日趋势">
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }
 
  return (
    <Panel
      title="收入日趋势" 
    >
      {data?.length > 0 ?<Area
        height={200}
        padding={[20,20,23,48]}
        smooth={true}
        color="#E4F6FF"
        data={fillData}
        xField="time"
        yField="incomeTotal"
        startOnZero={false}
        line={{
          color:'#02E6CB',
        }}
        xAxis={{
          // type: 'linear',
          label: {
            style: {
              fill: '#fff'
            }
          },
          line: {
            style: {
              stroke: '#12708D'
            }
          },
         
          // tickInterval: 1,
          range: [0, 1],
          tickLine: null
        }}
        yAxis={{
          label: {
            style: {
              fill: '#fff'
            },
            formatter: v => `${v}`,
          },
          line: {
            style: {
              stroke: '#12708D'
            }
          },
          grid: {
            line: {
              style: {
                stroke: '#12708D',
                strokeOpacity: 0.4
              }
            }
          },
          minTickInterval: 50,
          min: 0
        }}
        areaStyle={{
          fill: 'l(90) 1：#ffffff 0.5:#A9FEF3 0:#02E6CB'
        }}
        tooltip={{
          formatter: (data) => {
            return {
              name: `${data.time} 总收入`,
              value: `${data.incomeTotal}元`
            }
          },
          showTitle: false
        }}
        animation={{
          appear: {
            animation: 'path-in',
            duration: 3000
          },
          enter: {
            easing: 'easeLinear',
            duration: 3000,
            delay:2000,
          }
          
        }}
       
      />
      :<div className="fetch_container" style={{ height: 200 }}>
      暂无数据
    </div>
    } 
    </Panel>
  )
}

export default ParkingDailyIncome;