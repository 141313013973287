import React from 'react';
import { Modal } from 'antd';
import styles from './index.module.scss';

interface NotBoundMobileModalProps {
  visible: boolean;
  onClose: () => void;
  orderDetails?: API.RoadData;
}

const NotBoundMobileModal: React.FC<NotBoundMobileModalProps> = ({
  visible,
  onClose, 
  orderDetails
}) => {
  const orderStatusMap: { [key: number]: string } = {
  1: "进行中",    // 1 表示 "进行中"
  3: "待缴费",    // 3 表示 "待缴费"
  5: "已缴费"     // 5 表示 "已缴费"
};
  return (
    <Modal
      className={styles.picture_window}
      width={520}
      footer={null}
      visible={visible}
      onCancel={onClose}
      closeIcon={<img src="/img/icon_close_circle.png" alt="" className={styles.picture_window_close} />}
      maskStyle={{background: 'transparent'}}
    >
      <div className={styles.picture_content}>
        {orderDetails?.roadOrderVo ? (
          <>
            <div className={styles.box_item}>
        
               <div className={styles.box_item_left}>
                <span className={styles.box_item_title}>驶入时间：</span>
                <span className={styles.box_item_content}>{orderDetails?.roadOrderVo?.driveInTime || '--'}</span>
              </div>
              <div className={styles.box_item_right}>
                <span className={styles.box_item_title}>泊位号：</span>
                <span className={styles.box_item_content}>{orderDetails?.roadOrderVo?.berthNo || '--'}</span>
              </div>
            </div>
            <div className={styles.box_item}>
              <div className={styles.box_item_left}>
                <span className={styles.box_item_title}>车牌号：</span>
                <span className={styles.box_item_content}>{orderDetails?.roadOrderVo?.plateNo || '--'}</span>
              </div>
              <div className={styles.box_item_right}>
                <span className={styles.box_item_title}>订单状态：</span>
                <span className={styles.box_item_content}>{orderStatusMap[orderDetails?.roadOrderVo?.orderStatus]  || '未知'}</span>
              </div>
             
            </div>
            <div className={styles.box_item}>
              <div>
                <span className={styles.box_item_title}>驶入图片：</span>
                <div className={styles.img_box}>
                  <img src={orderDetails?.roadOrderAttach?.driveInPhoto1} alt="" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.loading}>加载中...</div>
        )}
      </div>
      {/* {src
      ?
      extraInfo != undefined &&<> <div className={styles.picture_info}>
      { extraInfo?.carType != undefined &&  <div>车辆类型：{extraInfo.carType}</div>}
      { extraInfo?.carBand != undefined &&  <div>车辆品牌：{extraInfo.carBand}</div>}
      { extraInfo?.carColor != undefined &&  <div>颜色：{extraInfo.carColor}</div>}
      { extraInfo?.carYear != undefined &&  <div>车辆年款：{extraInfo.carYear}</div>}
    </div>
    
      <img width="492" src={src} alt=""/>
      </>
      :
      <div  className={styles.nopicture_text}>暂无图片</div>} */}
    </Modal>
  )
}

export default NotBoundMobileModal;
