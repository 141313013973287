import React, { useCallback } from 'react';
import { useRequest } from 'ahooks';
import Panel from '@/components/Panel';
import { Area } from '@ant-design/charts';
import { GetIncomeTrendMonth, GetIncomeTrendMonthV2 } from '@/services/api';
import { Spin } from 'antd'
import ImgSource from '@/assets/img/jingying/shouruyuequshi@2x.png'

export interface ParkingMonthlyIncomeProps {
  areaCode?: string;
}

const ParkingMonthlyIncome:React.FC<ParkingMonthlyIncomeProps> = ({areaCode}) => {
  const { data = [], loading, error } = useRequest(useCallback(() => {
    // return GetIncomeTrendMonth(areaCode,'510500');
    return GetIncomeTrendMonthV2(areaCode,'441800');
  },[areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })

  if(loading) {
    return (
      <Panel title="收入月趋势"  >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel title="收入月趋势"  >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }
 
  return (
    <Panel
      title="收入月趋势"  
    >
     {data?<Area
        height={200}
        padding={[20,20,23,48]}
        smooth
        color="#E4F6FF"
        data={data}
        xField="dayStr"
        yField="incomeTotal"
        line={{
          color:'#8465EE',
        }}
        xAxis={{
          // type: 'linear',
          label: {
            style: {
              fill: '#fff'
            }
          },
          line: {
            style: {
              stroke: '#12708D'
            }
          },
          // max: 12,
          range: [0, 1],
          tickInterval: 1,
          tickLine: null
        }}
        yAxis={{
          
          label: {
            style: {
              fill: '#fff'
            },
            formatter: v => `${v}`,
          },
          line: {
            style: {
              stroke: '#12708D'
            }
          },
          grid: {
            line: {
              style: {
                stroke: '#12708D',
                strokeOpacity: 0.4
              }
            }
          },
          minTickInterval: 50,
          min: 0
        }}
        areaStyle={{
          fill: 'l(90) 1：#ffffff 0.5:#C6B7F7 0: #8465EE'
        }}
        tooltip={{
          formatter: (data) => {
            return {
              name: `总收入`,
              value: `${data.incomeTotal}`
            }
          },
          showTitle: false
        }}
        animation={{
          appear: {
            animation: 'path-in',
            duration: 3000
          },
          update: {
            easing: 'easeLinear',
            duration: 3000,
          }
          
        }}
      />
      :<div className="fetch_container" style={{ height: 200 }}>
      暂无数据
    </div>
    }
    </Panel>
  )
}

export default ParkingMonthlyIncome;